<template>
<div class="deposit-container">
    <p class="title">
        واریز تومانی
    </p>
    <div class="info-container">
        <div class="coin-info">
            <div>
                <p class="label">
                    انتخاب شماره کارت
                </p>
                <div class="selected-container" @click="drop = !drop">
                    <!-- <img src="/coins/BITCOIN.png" alt=""> -->
                    <span>6037697422921137</span>
                    <img class="dropIcon" src="@/assets/dropIcon.svg" alt="">
                    <div class="drop-down" v-if="drop">
                        <div>
                            <span>6037697422921137</span>
                        </div>
                        <div>
                            <span>6037697422921137</span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <p class="label">
                    مقدار برداشتی
                </p>
                <input class="address-input" type="text" v-model="address">
            </div>
            <button class="normal-btn">
               انتقال به درگاه پرداخت
            </button>
        </div>
        <div class="guide-container">
            <div>
                راهنمای واریز
            </div>
            <div class="warning-container">
                <img src="@/assets/warning.svg" alt="">
                <span>
                    تنها از کارت های عضو شتاب که در پروفایل شما تایید شده اند می توانید واریز انجام دهید
                </span>
            </div>
            <div class="limit-container">
                <span>
                    <img src="@/assets/plus.svg" alt="">
                    <span>افزودن شماره شبا</span>
                </span>
            </div>
        </div>
    </div>
    <div class="history-container">
        <p>
            تاریخچه واریز تومانی
        </p>
        <div class="table-contaner">
            <table>
                <tr class="tr-head">
                    <td class="address">
                        زمان
                    </td>
                    <td>
                        مقدار درخواستی
                    </td>
                    <td class="address">
                        شماره کارت
                    </td>
                    <td>
                        وضعیت
                    </td>
                    <td class="address">
                        شناسه پیگیزی
                    </td>
                </tr>
                <tr class="tr-body" v-for="item , index in 7" :key="index">
                    <td class="address">
                        ۰۰/۲/۲ ۱۲:۲۳
                    </td>
                    <td>
                        500000
                    </td>
                    <td class="address">
                        101900000087987987
                    </td>
                    <td class="success">
                        موفق
                    </td>
                    <td class="address">
                        87987987
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'RialWithdrawal',
    data() {
        return {
            address: 0,
            drop: false
        }
    },

}
</script>

<style lang="scss" scoped>
.title {
    color: #282929;
    font-size: 20px;
    margin: 0 12px;
}

.deposit-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.info-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
}

.history-container {
    background: #FFFFFF;
    border-radius: 12px;
    width: 100%;
    max-width: 1600px;
    height: 700px;
    padding: 20px;
}

.guide-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    row-gap: 25px;
    background: #FFFFFF;
    flex-grow: 1;
    border-radius: 12px;
    min-width: 300px;
    flex-grow: 1;
    max-width: 800px;

    div {
        width: 100%;
        text-align: right;
    }
}

.warning-container {
    width: 100%;
    border-radius: 12px;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    // justify-content: space-between;
    column-gap: 10px;
    background: #FFF6D4 !important;
    min-height: 72px;
    color: #D09C0A;
}

.coin-info {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: #FFFFFF;
    min-height: 226px;
    flex-grow: 1;
    border-radius: 12px;
    min-width: 300px;
    flex-grow: 1;
    max-width: 800px;
    justify-content: space-between;
    row-gap: 20px;
}

.address-input {
    background: #ECEEEF;
    border-radius: 4px;
    height: 44px;
    width: 100%;
    padding: 0 10px;

}

.selected-container {
    background: #ECEEEF;
    border-radius: 4px;
    height: 44px;
    width: 100%;
    padding: 0 10px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    position: relative;
    cursor: pointer;

    img {
        width: 30px;
    }
}

.success {
    color: #18D04B;
}

.table-contaner {
    width: 100%;
    height: 100%;
    padding: 0 20px;
}

table {
    border-collapse: collapse;
    width: 100%;
    font-size: 13px;
    color: #333333;
}

.tr-head {
    color: #888E95;
}

table tr {
    border-bottom: 0.6px solid rgba(61, 61, 61, 0.15);
    height: 45px;
    text-align: center;
}

.label {
    font-size: 14px;
    color: #4F4F4F;
    margin: 5px 0;
}

.dropIcon {
    position: absolute;
    left: 10px;
    width: 20px !important;
}

.drop-down {
    position: absolute;
    width: 100%;
    top: 45px;
    background: #ECEEEF;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px;
    flex-direction: column;
    cursor: pointer;

    div {
        height: 50px;
        display: flex;
        align-items: center;
        column-gap: 10px;
        width: 100%;
        cursor: pointer;

        // justify-content: space-between;
    }

    div:hover {
        background: #fcfeff;
    }

}

.limit-container {
    display: flex;
    color: #D09C0A;
    font-size: 12px;
    column-gap: 20px;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;

    span {
        display: flex;
        align-items: center;
        column-gap: 10px;
    }
}

.normal-btn {
    background: #EFBB29;
    border-radius: 8px;
    width: 100%;
    height: 40px;
    margin-top: 20px;
}
.cash {
    color: #4F4F4F;
    font-size: 12px;
    margin-top: 15px;

}
@media only screen and (max-width: 900px) {
    .date , .address , .destination , .trnasactionId {
        display: none;
    }
}
</style>
